import React, { Component } from 'react'
import { Button } from 'reactstrap'
import fire from './fire.js'
import Scores from './Scores'

export default class Performer extends Component {
  removePerformer = performer => {
    fire.collection("performers").doc(performer.id).delete().then(e => {
      this.props.getPerformers();
    })
  }
  render() {
    return (
      <div className="performance">
        <h2>{this.props.performer.data().name}</h2>
        <Scores 
          performance={this.props.performer}
          user={this.props.user}
        />
        {this.props.editMode && (
          <Button
            onClick={() => this.removePerformer(this.props.performer)}
          >
            Remove Performance
          </Button>
        )}
      </div>
    )
  }
}
