import firebase from 'firebase'
var config = {
  apiKey: "AIzaSyBFfX9J50csEXQ3isYytEJ_KCsJGJeV7DA",
  authDomain: "lipsync-16ab2.firebaseapp.com",
  databaseURL: "https://lipsync-16ab2.firebaseio.com",
  projectId: "lipsync-16ab2",
  storageBucket: "lipsync-16ab2.appspot.com",
  messagingSenderId: "208962233126"
};
const fire = firebase.initializeApp(config).firestore();

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();

export default fire;