import React, { Component } from 'react';
import PerformanceView from './PerformanceView'
import { Button, ButtonGroup } from 'reactstrap';
import Overview from './Overview'
import fire, { auth, provider } from './fire.js'
import './App.css';

class App extends Component {
  state = {
    renderView: PerformanceView,
    performers: [],
    user: null
  }

  getPerformers = () => {
    this.setState({
      performers: []
    })
    fire.collection('performers').get().then(performers => {
      performers.forEach(performer => {
        this.setState({
          performers: this.state.performers
            .filter(p => p.data().name !== performer.data().name)
            .concat(performer)
        })
      })
    })
  }

  componentDidMount() {
    this.getPerformers();
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      } 
    });
  }

  login = () => {
    auth.signInWithPopup(provider) 
      .then((result) => {
        const user = result.user;
        this.setState({
          user
        });
      });
  }

  render() {
    if (!this.state.user) {
      return (
        <div className="App">
          <header>
            <h1>Lipsync Battle!</h1>
          </header>
            <Button onClick={this.login}>Log In</Button>              
        </div>
      )
    }
    return (
      <div className="App">
        <header>
          <h1>Lipsync Battle!</h1>
        </header>
        <ButtonGroup
          className="button-group"
        >
          <Button 
            onClick={() => this.setState({ renderView: PerformanceView })}
            color={this.state.renderView === PerformanceView ? "primary" : "secondary"}
          >
            Performances
          </Button>
          <Button 
            onClick={() => this.setState({ renderView: Overview })}
            color={this.state.renderView === Overview ? "primary" : "secondary"}
          >
            Overview
          </Button>
        </ButtonGroup>
        <div className="content-area">
          {React.createElement(this.state.renderView, {
            performers: this.state.performers,
            getPerformers: this.getPerformers,
            user: this.state.user
          })}
        </div>
      </div>
    );
  }
}

export default App;
