import React, { Component } from 'react'

export default class OverviewRow extends Component {
  getPerformanceScore = type => {
    return this.props.performanceScore.filter(score => {
      return score.data().type === type
    }).reduce((acc, score) => acc + parseInt(score.data().score), 0)
  }

  getAverageScore = () => {
    return this.props.performanceScore.reduce((acc,score) => {
      return acc + parseInt(score.data().score)
    }, 0)
  }

  render() {
    return (
      <tr>
        <td>{this.props.performance.data().name}</td>
        <td>{this.getPerformanceScore("teamwork")}</td>
        <td>{this.getPerformanceScore("lipsync")}</td>
        <td>{this.getPerformanceScore("showmanship")}</td>
        <td>{this.getPerformanceScore("creativity")}</td>
        <td>{this.getAverageScore()}</td>
      </tr>
    )
  }
}
