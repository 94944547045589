import React, { Component } from 'react'
import StarRatings from 'react-star-ratings';

export default class Score extends Component {
  render() {
    return (
      <div className="score">
        <span className="score-title">{this.props.type.toUpperCase()}</span>
        <StarRatings
          rating={this.props.rating}
          starRatedColor="orange"
          changeRating={score => this.props.changeScore(this.props.type,score,this.props.performance)}
          numberOfStars={5}
          name={"rating-"+this.props.type}
          starDimension="25px"
        />
      </div>
    )
  }
}
