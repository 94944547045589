import React, { Component } from 'react'
import Score from './Score'
import fire from './fire.js'

export default class Scores extends Component {
  state = {
    scores: {
      showmanship: 0,
      teamwork: 0,
      lipsync: 0,
      creativity: 0
    }
  }

  getScores = performance => {
    fire.collection('scores')
    .where("performanceId", "==", performance.id)
    .where("user", "==", this.props.user)
    .get().then(scores => {
      scores.forEach(score => {
        this.setState({
          scores: Object.assign({}, this.state.scores, { [score.data().type]: score })
        })
      })
    })
  }

  componentDidMount() {
    this.getScores(this.props.performance)
  }

  changeScore = (type, score, performance) => {
    if (this.state.scores[type] === 0) {
      fire.collection('scores').add({
        type,
        score,
        performanceId: performance.id,
        user: this.props.user
      })
    } else {
      fire.collection('scores').doc(this.state.scores[type].id).set({
        score
      }, { merge: true })
    }
    this.getScores(performance);  
  }

  getRating = data => {
    return data ? data.data().score : data
  }

  render() {
    return (
      <div>
        <Score
          type="showmanship"
          performance={this.props.performance}
          changeScore={this.changeScore}
          rating={this.getRating(this.state.scores.showmanship)}
        />
        <Score
          type="teamwork"
          performance={this.props.performance}
          changeScore={this.changeScore}
          rating={this.getRating(this.state.scores.teamwork)}
        />
        <Score
          type="lipsync"
          performance={this.props.performance}
          changeScore={this.changeScore}
          rating={this.getRating(this.state.scores.lipsync)}
        />
        <Score
          type="creativity"
          performance={this.props.performance}
          changeScore={this.changeScore}
          rating={this.getRating(this.state.scores.creativity)}
        />
      </div>
    )
  }
}