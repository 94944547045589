import React, { Component } from 'react'
import { Button } from 'reactstrap'
import fire from './fire.js'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import Performer from './Performer'

export default class Performers extends Component {
  state = {
    performance: '',
    performers: []
  }

  addPerformance = performance => {
    fire.collection('performers').add({
      name: performance
    })
    this.setState({
      performance: ''
    })
    this.props.getPerformers();
  }

  changePerformance = e => {
    this.setState({
      performance: e.target.value
    })
  }
  
  render() {
    return (
      <div className="performers">
      {this.props.editMode && (
        <div className="performers-button">
          <Input  
            onChange={this.changePerformance}
            value={this.state.performance}
          />
          <Button
              onClick={() => this.addPerformance(this.state.performance)}
          >
              Add Performance
          </Button>
        </div>
      )}
        {this.props.performers && this.props.performers.length > 0 && (
          this.props.performers.map((performer, i) => {
            return (
              <div key={i}>
                <Performer
                  performer={performer}
                  getPerformers={this.props.getPerformers}
                  editMode={this.props.editMode}
                  user={this.props.user}
                />
              </div>
            )
          })
        )}
      </div>
    )
  }
}
