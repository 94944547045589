import React, { Component } from 'react';
import logo from './logo.svg';
import Performers from './Performers'
import { Button, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import './App.css';

export default class PerformanceView extends Component {
  state = {
    editMode: false,
    savedName: false
  }
  render() {
    const buttonClass = this.state.editMode ? "performance-view-button active" : "performance-view-button"
    return (
      <div className="performance-view">
        <div className="name-input">
          <Input
            className="performance-view-input"
            value={this.props.user.displayName}
            disabled
          />
          {/* <Button 
            onClick={() => this.setState({ savedName: true })}
            className="performance-view-save-button"
          >
            Save Name
          </Button> */}
        </div>
        {this.props.user.displayName && (
        <React.Fragment>
          <div 
            className={buttonClass}
            onClick={() => this.setState({editMode: !this.state.editMode})}
          >
           <FontAwesomeIcon
             icon={faEdit}
           >
             Edit Mode {this.state.editMode ? "Off" : "On"}
           </FontAwesomeIcon>
          </div>
            <Performers 
              editMode={this.state.editMode}
              user={this.props.user.displayName}
              performers={this.props.performers}
              getPerformers={this.props.getPerformers}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

