import React, { Component } from 'react'
import { Table } from 'reactstrap'
import fire from './fire.js'
import OverviewRow from './OverviewRow'
import './overview.scss'

export default class Overview extends Component {
  state = {
    scores: {}
  }
  componentDidMount() {
    this.getScores();
  }
  
  getScores = () => {
    fire.collection('scores')
    .get().then(scores => {
      scores.forEach(score => {
        const oldScores = this.state.scores[score.data().performanceId]
        const performanceScores = oldScores ? oldScores.concat(score) : [score]
        this.setState({
          scores: Object.assign({}, this.state.scores, { 
            [score.data().performanceId]: performanceScores
           })
        })
      })
    })
  }

  render() {
    return (
      <div className="overview-area">
        <Table className="overview-table">
          <thead>
            <tr>
              <th>Team</th>
              <th>Teamwork</th>
              <th>Lipsync</th>
              <th>Showmanship</th>
              <th>Creativity</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {this.props.performers.map(performer => {
              return (
                <OverviewRow 
                  performance={performer} 
                  performanceScore={this.state.scores[performer.id] || []}
                />
              )
            })}
          </tbody>
        </Table>
      </div>
    )
  }
}
